<template>
  <div class="mb-5">
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex">
        <v-icon v-if="icon" left size="30" color="primary">mdi-{{ icon }}</v-icon>
        <h2 class="text-h4 font-weight-black">{{ title }}</h2>
      </div>
      <slot name="actions"></slot>
    </div>

    <v-divider class="my-5"></v-divider>
  </div>

</template>

<script>

export default {
  name: 'AdminTitle',
  props: ['title', 'icon']
}

</script>
